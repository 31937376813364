.circular-progress {
    position: relative;
    .circular-progress-orientation {
        transform: rotate(-90deg);
    }

    .circular-progress-indicator--spinner {
        animation: spinner 0.75s linear infinite;
        transform-origin: center;
    }

    .circular-progress-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .circular-progress-label__loading {
        opacity: 0.5;
        font-size: 0.75em;
    }

    .circular-progress-label__progress {
        font-size: 1.5em;
        font-weight: bold;
    }

    .circular-progress-label__loading,
    .circular-progress-label__progress {
        display: block;
    }

    @keyframes spinner {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
