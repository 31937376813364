@import "./_variables";

.text {
  &-white {
    color: white;
  }
  &-green {
    color: green;
  }
  &-red {
    color: red !important;
  }
  &-yellow {
    color: yellow;
  }
  &-200 {
    font-weight: 200;
  }
  &-300 {
    font-weight: 300;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
  }
  &-800 {
    font-weight: 800;
  }
  &-right {
    text-align: right;
  }
}

.d-flex {
  display: flex;
}

.p {
  &-x {
    &-1 {
      padding: 10px;
    }
    &-2 {
      padding: 20px;
    }
    &-3 {
      padding: 30px;
    }
    &-4 {
      padding: 40px;
    }
  }
  &-y {
    &-1 {
      padding: 10px;
    }
    &-2 {
      padding: 20px;
    }
    &-3 {
      padding: 30px;
    }
    &-4 {
      padding: 40px;
    }
    &-5 {
      padding: auto 50px;
    }
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.bounce {
  -webkit-animation: bounce 3s ease infinite;
  -moz-animation: bounce 3s ease infinite;
  -ms-animation: bounce 3s ease infinite;
  -o-animation: bounce 3s ease infinite;
  animation: bounce 3s ease infinite;
}

.loader-no-bg {
  background-color: transparent !important;
  height: 100% !important;
}

.message {
  margin-top: 14px;
  font-size: 14px;
  margin-bottom: 14px;
}

.success {
  color: $primary-muted-color;
}

.error {
  color: $cancel-color;
}
.text-animate {
  .content {
    position: relative;
    margin-left: auto;
    text-align: center;
    width: fit-content;
    margin-right: auto;
  }

  .content h2 {
    color: #fff;
    font-size: 6em;
    // position: absolute;
    transform: translate(-50%, -50%);
    @media screen and (max-width: $medium-screen) {
      font-size: 2rem;
    }
  }

  .content h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px white;
  }

  .content h2:nth-child(2) {
    color: $primary-color;
    animation: animate 4s ease-in-out infinite;
  }

  @keyframes animate {
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }
    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
  }
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    transparent 0%,
    transparent 29%,
    transparent 67%,
    $secondary-color 100%
  );
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
