@import "src/components/ui/__variables";

.content {
  padding: 60px 0px 0px;
  .flex-images {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .image {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    img {
      // width: 100%;

      &.center {
        margin-left: auto;
        margin-right: auto;
        width: 800px;
        @media screen and (max-width: $large-screen) {
          width: 100%;
        }
        @media screen and (max-width: $medium-screen) {
          width: 100%;
        }
        @media screen and (max-width: $small-screen) {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: $small-screen) {
    padding: 40px 0px;
  }
  h1 {
    color: $white-color;
    line-height: 52px;
    font-size: 32px;
    font-weight: bold;
    &.center {
      text-align: center;
      width: 800px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: $large-screen) {
        width: 100%;
      }
    }
    @media screen and (max-width: $medium-screen) {
      font-size: 28px;
      line-height: 44px;
    }
    @media screen and (max-width: $small-screen) {
      font-size: 24px;
      line-height: 42px;
    }
  }
  h2 {
    color: $primary-color;
    line-height: 40px;
    font-size: 24px;
    @media screen and (max-width: $medium-screen) {
      font-size: 20px;
      line-height: 40px;
    }
  }
  p {
    color: $white-color;
    font-size: 20px;
    font-weight: 500;
    opacity: 0.8;
    margin-top: 16px;
    @media screen and (max-width: $medium-screen) {
      font-size: 18px;
    }
    @media screen and (max-width: $small-screen) {
      font-size: 18px;
      line-height: 30px;
    }
    span {
      color: $primary-color;
      font-weight: bold;
      text-transform: uppercase;
    }
    &.center {
      text-align: center;
      width: 800px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: $large-screen) {
        width: 100%;
      }
    }
    @media screen and (max-width: $medium-screen) {
      font-size: 28px;
      line-height: 44px;
    }
    @media screen and (max-width: $small-screen) {
      font-size: 24px;
      line-height: 42px;
    }
  }
  a {
    color: $white-color;
    opacity: 0.8;
  }
  &__testimonials {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 32px;
    @media screen and (max-width: $large-screen) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $small-screen) {
      grid-template-columns: repeat(1, 1fr);
    }
    div {
      width: 100%;
      // height: 450px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .pbenefit-images {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    .image-left {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .images {
      display: flex;
      gap: 20px;
      @media screen and (max-width: $medium-screen) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      img {
        width: 100%;
      }
    }
  }
  .middle {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }

  .center {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  li {
    font-size: 16px;
    list-style: none;
  }
  ol {
    line-height: 30px;
  }
  .small{
    font-size: 14px;
  }
}
