@import "../_variables";
.custom-dropdown {
  button {
    background-color: $white-color !important;
    border: 1px solid $white-muted-color;
    color: $black-color;
  }
  button:focus,
  button:hover {
    border: 1px solid $white-muted-color;
    color: $primary-color !important;
  }
  button:focus:not(:focus-visible) {
    border: 1px solid $primary-color;
    color: $primary-color !important;
  }
  .btn-check:focus + .btn,
  .btn:focus {
    border: 1px solid $primary-color;
    box-shadow: none !important;
    color: $primary-color !important;
  }
  .dropdown-menu {
    background-color: $white-color;
    line-height: 1.5rem;
    font-size: 0.9rem;
    margin-top: 0.2rem;
    border: 1px solid $primary-color;
  }
}
