@import "src/components/ui/__variables";

.home {
  padding: 0px 150px;

  padding-bottom: 100px;

  // background-color: $white-color !important;

  background-color: $background-color !important;

  @media screen and (max-width: $large-screen) {
    padding: 0px 70px;
  }

  @media screen and (max-width: $medium-screen) {
    padding: 0px 50px;
  }

  @media screen and (max-width: $small-screen) {
    padding: 0px 24px;
  }
}
