@import "src/components/ui/__variables";

.about-us {
  background-color: $background-color;
  min-height: 100vh;
  width: 100%;

  padding: 100px 140px;

  @media screen and (max-width: $large-screen) {
    padding: 50px 70px;
  }

  @media screen and (max-width: $medium-screen) {
    padding: 50px 50px;
  }

  @media screen and (max-width: $small-screen) {
    padding: 30px 20px;
  }

  &__heading {
    h3 {
      color: $white-color;
      font-size: 42px;
    }
    h4 {
      color: $white-color;
      opacity: 80%;
      font-size: 20px;
    }
    p {
      color: $white-muted-color;
      font-size: 16px;
    }
    @media screen and (max-width: $medium-screen) {
      h3 {
        font-size: 32px;
      }
      p {
        font-size: 18px;
      }
    }
    @media screen and (max-width: $small-screen) {
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    margin-bottom: 32px;
  }

  &__content {
    margin-bottom: 16px;
    h4 {
      color: $primary-color;
      font-size: 24px;
    }
    p {
      color: $white-muted-color;
      font-size: 16px;
      line-height: 32px;
      opacity: 70%;
    }

    &__images {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      margin-bottom: 16px;
      img {
        height: 600px;
        width: 100%;
      }

      @media screen and (max-width: $medium-screen) {
        flex-direction: column;
        img {
          height: 350px;
        }
      }
    }

    video {
      margin-top: 16px;
      margin-bottom: 16px;
      // height: 600px;

      @media screen and (max-width: $medium-screen) {
        width: 100%;
      }
    }

    @media screen and (max-width: $medium-screen) {
      h4 {
        font-size: 28px;
      }
    }
    @media screen and (max-width: $small-screen) {
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
