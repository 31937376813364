@import "src/components/ui/__variables";

.contact-us {
  background-color: $background-color;
  min-height: fit-content;
  width: 100%;
  display: flex;
  padding-bottom: 40px;
  @media screen and (max-width: $large-screen) {
    // padding: 0px 70px;
  }

  @media screen and (max-width: $medium-screen) {
    display: block;
  }

  @media screen and (max-width: $small-screen) {
    // padding: 0px 24px;
    display: block;
  }

  img {
    // width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 450px;
    // display: none;
    @media screen and (max-width: $medium-screen) {
      width: 100%;
      height: 100%;
    }
  }
  section {
    width: 50%;
    h3 {
      color: $white-color;
      font-size: 42px;
    }
    p {
      color: $white-color;
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &:last-child {
      padding: 200px 50px;
      @media screen and (max-width: $medium-screen) {
        padding: 20px 50px;
        width: 100%;
        p {
          font-size: 18px;
        }
        h3 {
          font-size: 32px;
        }
      }
      @media screen and (max-width: $small-screen) {
        padding: 0px 20px;
        width: 100%;
        p {
          font-size: 16px;
        }
        h3 {
          font-size: 24px;
        }
      }
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   align-items: center;
    }
    &:first-child {
      padding: 60px 0px;
      @media screen and (max-width: $medium-screen) {
        padding: 0;
        width: 100%;
      }
    }
  }
}
