@import "../../_variables";

.default-modal {
  padding: 1.5rem;
  background-color: $background-color;
  border: 0.5px solid rgba($secondary-color, 0.2);
  border-radius: $border-radius !important;
  overflow-y: scroll !important;
  max-height: 80vh !important;
  @media screen and (max-width: $medium-screen) {
    bottom: 0px !important;
    position: fixed;
    right: 10px;
    left: 10px;
  }

  .icon {
    margin-left: auto;
    display: block;
    width: fit-content;
    color: $cancel-color;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .close--button {
    width: 100%;
    background-color: $primary-color;
    height: 3rem;
    font-weight: 600;
    margin-top: 2rem;
  }
}
