@import "src/components/ui/__variables";

.view-product {
  img {
    width: 150px;
    height: 150px;
  }
  h3 {
    color: $primary-color;
    font-weight: 800;
    font-size: 32px;
  }
  h4 {
    color: $white-color;
    font-size: 18px;
  }
  p {
    color: $white-color;
  }
  button.delete {
    background-color: red !important;
    border: none;
    margin-top: 16px;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }
}
