@import "components/ui/__variables";

.get-started {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  height: 100vh;
  padding-top: 100px;
  background-color: $background-color;
  h1 {
    color: $white-color;
    text-align: center;
    margin-bottom: 1rem;
    @media screen and (max-width: $medium-screen) {
      font-size: 16px;
    }
  }
  @media screen and (max-width: $medium-screen) {
    // height: 100%;
  }
  form {
    width: 500px;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
    // padding: 1rem;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: $medium-screen) {
      width: 90%;
    }
    label {
      color: $white-color !important;
      display: none !important;
    }
    .text-input {
      width: 100% !important;
    }
    input {
      background-color: $background-color !important;
      border-width: 0.1px !important;
      color: $white-color !important;
      margin-top: 0 !important;
      border-radius: $border-radius 0px 0px $border-radius !important;
    }
    button {
      width: 100% !important;
      height: 3.5rem !important;
      border-radius: 0px $border-radius $border-radius 0px !important;
      background-color: $secondary-color !important;
      border: none !important;
      font-weight: bold !important;
      @media screen and (max-width: $medium-screen) {
      }
      &:hover {
        background-color: transparent !important;
        color: $white-color !important;
        border: 0.5px solid $secondary-color !important;
      }
    }
  }
  &__actions {
    margin-top: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .start_new {
      background-color: transparent !important;
      color: $white-color !important;
      border: 0.5px solid $secondary-color !important;
      width: 20rem !important;
      @media screen and (max-width: $medium-screen) {
        width: 100% !important;
        padding: 0px 1rem;
      }
    }
    .signup {
      width: 3rem;
      height: rem;
      background-color: transparent;
      border: 0.5px solid $white-color;
      display: none;
    }
  }
}
